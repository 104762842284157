import React, { Component } from 'react'

import Copyable from './Copyable'
import Typography from '@material-ui/core/Typography'
import AboutToznyEnc from '../common/AboutToznyEnc'

import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../styles.js'
import Button from '@material-ui/core/Button'


import { observer, inject } from 'mobx-react'

import {
  withRouter
} from 'react-router-dom'

class LinkAndPW extends Component {
  constructor(props) {
    super(props)
    this.props.store.message = 'Show'
    this.state = {
      showSeparately: false,
    }
  }

  onSeparately = () => {
    if (!this.state.showSeparately) {
      this.props.store.message = 'Hide'
    } else {
      this.props.store.message = 'Show'
    }
    this.setState({ showSeparately: !this.state.showSeparately })
  }


  render() {
    const { classes } = this.props
    return (
      <div
        className={classNames(
          classes.flex,
          classes.flexColumn,
          classes.alignItemsCenter,
          classes.border,
        )}
      >
        <div className={classNames(
          classes.width45vw,
          classes.minWidth,
          classes.alignSelfCenter
        )}
        > 
          <p class="label-text">
            Here is your sharing link:
          </p>
          <Copyable
            autoFocus={true}
            id="outlined-read-only-share-url"
            value={this.props.store.shareURL}
            className={classNames(
              classes.width45vw,
              classes.minWidth,
              classes.mb0
            )}
            margin="normal"
            /* eslint-disable react/jsx-no-duplicate-props*/
            InputProps={{
              readOnly: true,
              style: { fontSize: '20px', backgroundColor: 'white' }
            }}
            /* eslint-enable react/jsx-no-duplicate-props*/
            variant="outlined"
          />
          <p className={classNames(classes.mt0, classes.t10, classes.mb5)}> * This link is valid for 5 days. </p>
        </div>
        <div className={classNames(
          classes.width45vw,
          classes.minWidth,
          classes.alignSelfCenter
        )}
        >
          <Button
            onClick={this.onSeparately}
            variant="contained"
            id="showSeparately"
            className={classNames(
              classes.width300,
              classes.mt10,
              classes.mb10,
              classes.flex
            )}
            color="secondary"
            size='large'
          >
            {this.props.store.message} Separate Link and Password
          </Button>
        </div>
        {!this.state.showSeparately ? null : (
          <div className={classNames(
            classes.width45vw,
            classes.minWidth,
            classes.alignSelfCenter,
          )}
          >
            <p class="label-text">
              Here is your link:
            </p>
            <Copyable
              autoFocus={true}
              id="outlined-read-only-input-url"
              value={this.props.store.urlNoPW}
              className={classNames(classes.width45vw, classes.minWidth)}
              margin="normal"
              /* eslint-disable react/jsx-no-duplicate-props*/
              InputProps={{
                readOnly: true,
                style: { fontSize: '20px', backgroundColor: 'white' }
              }}
              /* eslint-enable react/jsx-no-duplicate-props*/
              variant="outlined"
            />
            <p class="label-text">
              Here is your password:
            </p>
            <Copyable
              id="outlined-read-only-input-pw"
              value={this.props.store.sharePW}
              className={classNames(classes.width45vw, classes.minWidth)}
              margin="normal"
              /* eslint-disable react/jsx-no-duplicate-props*/
              InputProps={{
                readOnly: true,
                style: { fontSize: '20px', backgroundColor: 'white' }
              }}
              /* eslint-enable react/jsx-no-duplicate-props*/
              variant="outlined"
            />
          </div>
        )}
        <div>
          < AboutToznyEnc />
        </div>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(inject('store')(observer(LinkAndPW))))
