import React from 'react'

import TextField from '@material-ui/core/TextField'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CopyIcon from '@material-ui/icons/LibraryBooks'
import CloseIcon from '@material-ui/icons/Close'

function Copyable(props) {
	const [open, setOpen] = React.useState(false)
	const [copied, setCopied] = React.useState(false)

	function handleClick() {
		setOpen(true);
	}

	function handleClose(event, reason) {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	}
	return (
		<div style={{ display: 'inline-flex' }}>
			<TextField {...props} />
			<CopyToClipboard text={props.value}
				onCopy={() => { 
					setCopied(true)
					handleClick()
				}}>
				<IconButton style={{ margin: '1em 0.25em' }} id="copy-link" aria-label="Copy to clipboard">
					<CopyIcon />
				</IconButton>
			</CopyToClipboard>
			{copied
				? <Snackbar 
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					open={open}
					autoHideDuration={6000}
					onClose={handleClose}
					ContentProps={{
						'aria-describedby': 'message-id',
					}}
					message={<span id="message-id">Copied to clipboard</span>}
					action={[
						<IconButton
							key="close"
							aria-label="Close"
							color="inherit"
							onClick={handleClose}
						>
							<CloseIcon />
						</IconButton>,
					]}
				/>
				: null}
		</div>
	)
}

export default Copyable
