import React, { Component } from 'react'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import AboutToznyEnc from '../common/AboutToznyEnc'

import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../styles.js'
import { withRouter } from "react-router"
import { observer, inject } from 'mobx-react'

import globals from '../Globals'
import Tozny from 'tozny-browser-sodium-sdk'

const Client = Tozny.Storage.Client
const crypt = Tozny.crypto

class RetrieveData extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errorMessage: '',
      showErrorMessage: false,
      badURL: false
    }
  }

  handleClose = e => {
    this.setState({ showErrorMessage: false })
  }

  getData = async () => {

    if (this.props.store.presentedPassword) {
      let password = this.props.store.presentedPassword
      let encNonce = await crypt.b64decode(this.props.store.urlEncNonce)
      let signNonce = await crypt.b64decode(this.props.store.urlSignNonce)
      let keypair = await crypt.deriveCryptoKey(password, encNonce)
      let signingKeypair = await crypt.deriveSigningKey(password, signNonce)

      const noteId = this.props.store.urlNoteId
      const storageURL = globals.storageURL
      let newNote

      try {
        newNote = await Client.readNote(
          noteId,
          keypair,
          signingKeypair,
          storageURL
        )
      } catch (err) {
        if (err.message === 'Unauthorized') {
          this.props.store.previousPassword = this.props.store.presentedPassword
          this.props.store.presentedPassword = null

          this.props.store.requestDecrypt = false
          return
        } else {
          this.props.history.push('/404')
          return
        }
      }

      let secret = ''

      for (let i in newNote.data) {
        secret += newNote.data[i]
      }

      this.props.store.decryptedData = secret
      this.props.store.requestDecrypt = true

    } else {
      this.setState({ errorMessage: true })
    }

  }

  render() {
    const { classes } = this.props
    return (
      <div className={classNames(
        classes.flex,
        classes.flexColumn,
        classes.alignItemsCenter,
        classes.justifyBetween,
        classes.m5)}
        id="continueCheck"
        >
        <div>
          <Typography className={classNames(classes.mt40)} variant="h5">Retrieve & Decrypt Data</Typography>
          <div className={classNames(classes.flex, classes.flexColumn, classes.flexWrap, classes.alignItemsStart)}>
            <br />
            <div>Are you sure you want to continue?<br />Clicking the button below will use one of the available retrieval credits for this secret. </div>
            <br />
            <div className={classNames(classes.flex, classes.flexColumn)}>
              <Button
                style={{ width: '200px' }}
                onClick={this.getData}
                variant="contained"
                className={classNames(
                  classes.height60,
                  classes.mt10,
                  classes.flex
                )}
                color="secondary"
                size='large'
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
        <div>
          <AboutToznyEnc />
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(inject('store')(observer(withRouter(RetrieveData))))
