import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'

import { observer, inject } from 'mobx-react'

import EncryptPage from './encryptComponents/EncryptPage'
import LinkAndPW from './encryptComponents/LinkAndPW'
import classNames from 'classnames'
import styles from '../styles.js'

class Encrypt extends Component {
  constructor(props) {
    super(props)
    this.state = { 
      dataProcessed: false
    }
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <div className={classNames("top-div")}>
        {!this.props.store.shareURL || !this.props.store.sharePW ? (
          <EncryptPage />
        ) : (
          <LinkAndPW />
        )}
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(inject('store')(observer(Encrypt)))
