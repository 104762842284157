import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { observer, inject } from 'mobx-react'
import styles from '../styles.js'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography'

class Faq extends Component {
  render() {
    const { classes } = this.props
    return (
        <div className={classNames(classes.flex, classes.flexColumn, classes.alignItemsCenter, classes.mt40)} style={{maxWidth: '900px', marginLeft: 'auto', marginRight: 'auto'}}>
            <div className={classNames(classes.flex, classes.flexColumn, classes.mt40)}  style={{maxWidth: '1280px'}}>
                <Typography variant="h6" gutterBottom>
                    What does "end to end encryption" mean?
                </Typography>
                <Typography variant="body1" gutterBottom>
                    It means your data is encrypted before it ever leaves your device and only decrypted after 
                    it arrives in the reader's device. All the cryptographic operations occur in your browser. 
                    The best way to secure your data is make sure only your intended recipients can read it. 
                    We can't read your data ever.  <a href="https://tozny.com/blog/end-to-end-encryption-vs-https/">Read more about it here</a>.
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Is encryption in the browser secure?
                </Typography>
                <Typography variant="body1" gutterBottom>
                Yes. Some security professionals worry that the nature of dynamic code on the web reduces the security. However, browser crypto has come a long way and the sodium crypto library is supported
                in all the major browsers.  End-to-end encryption makes your data drastically more secure and supporting that
                in the browser means more people can rely on strong encryption for privacy.
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Can Tozny access my data?
                </Typography>
                <Typography variant="body1" gutterBottom>
                    No. Your password is never transmitted over the internet. Your data is cryptographically 
                    secured before leaving your device, and only decrypted when it arrives in your reader's device. 
                    Tozny does not rely on policy-based access, or security by obscurity. We protect your 
                    data with cryptography — even from ourselves.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    Why is this free?
                </Typography>
                <Typography variant="body1" gutterBottom>
                    We think privacy through applied cryptography is important and using it should be easy. 
                    Please use this to keep sensitive information out of email and chat! In the future we 
                    may add premium features, but the basic function set is available to everyone. 
                    Have questions?  Email us anytime at info@tozny.com
                </Typography>

                <Typography variant="h6" gutterBottom>
                    What kind of cryptography does this use?
                </Typography>
                <Typography variant="body1" gutterBottom>
                    We use native browser cryptography features, including the natively 
                    available <a href="https://developer.mozilla.org/en-US/docs/Web/API/Crypto/getRandomValues" target='_new'>cryptographically 
                    secure random number generator</a>. In addition, much of the cryptography is implemented using libsodium. 
                    It's open source - check them out here <a href="http://nacl.cr.yp.to/" target="_new">NaCl</a>. Our 
                    implementation uses <a href="https://github.com/jedisct1/libsodium.js/" target='_new'>libsodium.js</a> which 
                    compiles the libsodium library to web assembly for use in the browser.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    I forgot my password, can you help?
                </Typography>
                <Typography variant="body1" gutterBottom>
                    No. By design we never have access to your password. In fact we not only do not store passwords, 
                    they are never transmitted to our server. They never even leave your browser.
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Why should I trust Tozny?
                </Typography>
                <Typography variant="body1" gutterBottom>
                    We've been building cryptographic solutions for years - we think we're pretty good at it.  
                    But you don't have to take our word for it. Since we're built on top of open source crypto 
                    you can check out what's happening under the hood.
                </Typography>
            </div>
      </div>
    )
  }
}

export default withStyles(styles)(inject('store')(observer(Faq)))
