import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'

import { observer, inject } from 'mobx-react'

import DecryptPage from './decryptComponents/DecryptPage'
import RetrieveData from './decryptComponents/RetrieveData'
import ViewData from './decryptComponents/ViewData'

const styles = {
  root: {
    flexGrow: 1
  }
}

class Decrypt extends Component {
  render() {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        { !this.props.store.presentedPassword ? ( 
            <DecryptPage />
          ) : null
        }
        {
          this.props.store.presentedPassword && !this.props.store.requestDecrypt ? (
            <RetrieveData /> ) 
            : null
        }
        {
          this.props.store.presentedPassword && this.props.store.requestDecrypt ? (
            <ViewData /> )
            : null
        }
      </div>
    )
  }
}

export default withStyles(styles)(inject('store')(observer(Decrypt)))
