import React, { Component } from 'react'

import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../styles.js'

import { observer, inject } from 'mobx-react'
import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

class AboutToznyEnc extends Component {
  render() {
    const { classes } = this.props
    return (
      <div
        className={classNames(
          classes.mt40,
          classes.bgWhite,
          classes.m5,
          classes.width45vw,
          classes.minWidth,
          "about-box"
        )}
      >
        <p
          color="primary"
          className={classNames(classes.ml10, classes.p10, "about-title")}
          variant="subheading"
        >
          About Tozny End-To-End Encryption
        </p>
        <p
          className={classNames(
            classes.ml12,
            classes.mr12,
            classes.p10,
            classes.mb64,
            "about-body"
          )}
        >
          Tozny products provide application-level security that secures data for its entire life-cycle. 
          Data gets encrypted where it is generated (written) and decrypted where it is consumed 
          (read), providing end-to-end encryption. As result, data carries its own security. 
          <br/><br />
          Learn more at <a href="https://www.tozny.com" target="_new">Tozny.com</a>
          <br/><br />
          Want to easily add strong encryption to your application?  We have SDKs in a number 
          of languages and integration is easy. <a href="https://dashboard.tozny.com" target="_new">Sign up for a free account</a>.
          <br />
          <br />
          <Link component={RouterLink} to="/faq">
            Frequently Asked Questions
          </Link>
        </p>
      </div>
    )
  }
}

export default withStyles(styles)(inject('store')(observer(AboutToznyEnc)))
