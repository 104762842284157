import React, { Component } from 'react'

import Typography from '@material-ui/core/Typography'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { ThemeProvider } from '@material-ui/styles';
import { withStyles } from '@material-ui/core/styles'

import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import { observer, inject } from 'mobx-react'

const styles = {
  root: {
    flexGrow: 1
  }
}

class NavBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      usesRemaining: 3
    }
  }

  handleClick = () => {
    this.props.store.sharePW = ''
    this.props.store.shareUrl = ''
  }

  render() {
    const { classes } = this.props
    return (
      <ThemeProvider theme={this.props.theme}>
      <div className={classes.root}>
        <AppBar position="static" color="primary" >
          <Toolbar>
            <Link onClick={this.handleClick} component={RouterLink} to="/">
              <Typography variant="h6" style={{color: '#ffffff'}}>
                Tozny - One Time Secret Sharing
              </Typography>
            </Link>
          </Toolbar>
        </AppBar>

      </div>
      </ThemeProvider>
    )
  }
}

export default withStyles(styles)(inject('store')(observer(NavBar)))
