import React, { Component } from 'react'

import NavBar from './components/common/NavBar'
import Encrypt from './components/Encrypt'
import Decrypt from './components/Decrypt'
import Page404 from './components/Page404'
import Faq from './components/Faq'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import mainLogo from'./main-logo.svg';
import { withStyles } from '@material-ui/core/styles'
import { observer, inject } from 'mobx-react'
import styles from './styles.js'
import classNames from 'classnames'
import ToznyFooter from './components/common/ToznyFooter'
import ToznyAbout from './components/common/ToznyAbout'
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom"

const theme = createMuiTheme({
  palette: {
    primary: { main: '#47b7ab' }, // Purple and green play nicely together.
    secondary: { main: '#4174df' },
    contrastText: '#ffffff',
    text: {
      secondary: "#ffffff",
    }
  }
});
class App extends Component {

  clear = () => {
    console.log("Ok")
      this.props.store.sharePW = ''
      this.props.store.shareUrl = ''
  }

  render() {
    const { classes } = this.props
    return (
      <MuiThemeProvider theme={theme}>
      <div className={classNames(classes.minHeight)}>
          
          <div id="stripes">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          
          <div class="content">
          <div class="header-bar" onClick={this.clear}>
            <img src={mainLogo} />
            <span class="header-labs-text">Labs</span>
          </div>
          <Router>
            {/* <NavBar theme={theme} /> */}
            <Switch>
              <Route
                exact
                path="/"
                name="Encrypt"
                component={Encrypt}
              >
              </Route>
              <Route
                path="/view"
                name="Decrypt"
                component={Decrypt}
              >
              </Route>
              <Route
                path="/404"
                name="Page404"
                component={Page404}
              >
              </Route>
              <Route
                path="/faq"
                name="FAQ"
                component={Faq}
              >
              </Route>
            </Switch>
          </Router>
          </div>
        
      </div>
      <ToznyAbout />
      <ToznyFooter />
      </MuiThemeProvider>
    )
  }
}

export default withStyles(styles)(inject('store')(observer(App)))
