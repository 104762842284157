import React, { Component } from 'react'


import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../styles.js'

import { observer, inject } from 'mobx-react'
import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import mainLogo from'../../main-logo-green.svg';

class ToznyFooter extends Component {
  render() {
    const { classes } = this.props
    return (
      <div
        className={classNames(
          "tozny-footer-box",
        )}
      >
        <div>
            <img className="inverse-logo" src={mainLogo} />
        </div>
        <div className="footer-row">
            <div className="footer-column">
                <div className="column-title">Company</div>
                <div className="column-entry">
                    <a href="https://tozny.com" target="_blank">About Us</a>
                </div>
                <div className="column-entry">
                    <a href="https://tozny.com/contact" target="_blank">Contact</a>
                </div>
            </div>
            <div className="footer-column">
                <div className="column-title">Products</div>
                <div className="column-entry">
                    <a href="https://tozny.com/tozid" target="_blank">TozId</a>
                </div>
                <div className="column-entry">
                    <a href="https://tozny.com/tozstore" target="_blank">TozStore</a>
                </div>
            </div>
            <div className="footer-column">
                <div className="column-title">Solutions</div>
                <div className="column-entry">
                    <a href="https://tozny.com/compliance" target="_blank">GDPR</a>
                </div>
                <div className="column-entry">
                    <a href="https://tozny.com/compliance" target="_blank">CCPA</a>
                </div>
            </div>
            <div className="footer-column">
                <div className="column-title">Resources</div>
                <div className="column-entry">
                    <a href="https://developers.tozny.com" target="_blank">Developers</a>
                </div>
                <div className="column-entry">
                    <a href="https://tozny.com/compliance" target="_blank">Compliance</a>
                </div>
                <div className="column-entry">
                    <a href="https://tozny.com/pricing" target="_blank">Pricing</a>
                </div>
                <div className="column-entry">
                    <a href="https://tozny.com/privacy-policy" target="_blank">Privacy Policy</a>
                </div>
                <div className="column-entry">
                    <a href="https://tozny.com/terms-of-use" target="_blank">Terms of Use</a>
                </div>
                <div className="column-entry">
                    <a href="https://dashboard.tozny.com" target="_blank">Sign Up</a>
                </div>
            </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(inject('store')(observer(ToznyFooter)))
