import React, { Component } from 'react'

import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles';
import FormControl from "@material-ui/core/FormControl";
import { styled } from '@material-ui/styles';
import AboutToznyEnc from '../common/AboutToznyEnc'

import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../styles.js'
import Checkbox from '@material-ui/core/Checkbox';
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import Tozny from 'tozny-browser-sodium-sdk'
import { observer, inject } from 'mobx-react'

import globals from '../Globals'

const Client = Tozny.Storage.Client
const crypt = Tozny.crypto

const custom = {
  select: {
    minWidth: '100%'
  }
};

const GreenCheckbox = withStyles({
  root: {
    color: "#1FB0A1",
    '&$checked': {
      color: "#1FB0A1",
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

class EncryptPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      usesAllowed: globals.uses,
      createPassword: false,
      createdPW: '',
      toEncrypt: '',
      errorMessage: '',
      showErrorMessage: false,
      loading: false,
      statusMessage: ""
    }
  }

  handleUsesChange = e => {
    const uses = e.target.value
    if (!isNaN(uses)) {
      this.setState({ usesAllowed: uses })
    }
  }

  handleClose = () => {
    this.setState({ showErrorMessage: false })
  }

  handleCheck = () => {
    this.setState({ createPassword: !this.state.createPassword })
  }

  handleToEncryptChange = e => {
    const id = e.currentTarget.id
    const data = e.currentTarget.value
    if (id === 'toEncrypt') {
      this.setState({ toEncrypt: data })
    }
  }

  handlePasswordChange = e => {
    const id = e.currentTarget.id
    const pw = e.currentTarget.value
    if (id === 'createPassword') {
      this.setState({ createdPW: pw })
    }
  }
  queueStatusMessages = () => {
    const list = [
      "Initializing Encryption Algorythms...",
      "Selecting libSodium...",
      "Reticulating Splines...",
      "Secure Connection Established...",
      "Verifying Signatures...",
      "Verifying Payloading...",
      "Enhancing..."
    ]
    

    let random = list[Math.floor(Math.random()*list.length)]
    console.log(random)
    if(this.state.loading){
      this.setState({statusMessage: random})
    }else{
      this.setState({statusMessage: ""})
      return;
    };

    let self = this;
    setTimeout(function(){
      self.queueStatusMessages();
    }, 150)
  }
  requestEncrypt = async () => {

    if (this.state.toEncrypt && this.state.usesAllowed) {
      if (this.state.toEncrypt.trim().length === 0) {
        const message = "Please enter a secret."
        this.setState({ errorMessage: message, showErrorMessage: true })
        this.setState({ toEncrypt: '' })
        return
      }

      if (this.state.createPassword && this.state.createdPW.trim().length === 0) {
        const message = "Please enter a password, or encrypt with a generated password."
        this.setState({ errorMessage: message, showErrorMessage: true })
        this.setState({ createdPW: '' })
        return
      }

      this.setState({loading: true}, this.queueStatusMessages)
      
      let self = this;
      setTimeout(function(){
        self.sendEncrypt()
      }, 2500)
    } else {
      const message = "Please enter a secret."
      this.setState({ errorMessage: message, showErrorMessage: true })
    }
  }

  sendEncrypt = async () =>{

    


      // generate all the needed keys.
      const {
        password,
        encNonce,
        signNonce,
        keypair,
        signingKeypair
      } = await this.makeAllTheThings()

      const b64EncNonce = await crypt.b64encode(encNonce)
      const b64SignNonce = await crypt.b64encode(signNonce)

      let writtenNote = await Client.writeNote(
        this.state.toEncrypt,
        keypair.publicKey,
        signingKeypair.publicKey,
        keypair,
        signingKeypair,
        {
          max_views: parseInt(this.state.usesAllowed),
          type: 'secretSharingApp',
        },
        globals.storageURL
      )
      this.setState({loading: false})
      const noteId = writtenNote.noteId

      const url = global.location + `view/${noteId}#sigSalt=${b64SignNonce}&encSalt=${b64EncNonce}`
      const shareUrl = url + `&password=${password}`

      this.props.store.sharePW = password
      this.props.store.noteId = noteId
      this.props.store.shareURL = shareUrl
      this.props.store.urlNoPW = url
    
  }

  makeAllTheThings = async () => {
    let password
    if (this.state.createPassword) {
      password = this.state.createdPW
    } else {
      password = await crypt.b64encode(await crypt.randomKey())
    }
    const encNonce = await crypt.randomNonce()
    const signNonce = await crypt.randomNonce()
    const keypair = await crypt.deriveCryptoKey(password, encNonce)
    const signingKeypair = await crypt.deriveSigningKey(password, signNonce)
    return { password, encNonce, signNonce, keypair, signingKeypair }
  }

  render() {
    const { classes } = this.props
   
    return (
      <div
        className={classNames(
          classes.flex,
          classes.flexColumn,
          classes.alignItemsCenter,
          classes.justifyCenter
        )}
      >
        <div>
          <div className={classNames(
            classes.flex,
            classes.flexColumn,
            classes.alignItemsCenter,
            classes.mt10
          )}>
          </div>
          <div className={classNames(
            classes.flex,
            classes.flexColumn,
            classes.alignItemsCenter,
            classes.mt10,
            classes.mb40
          )}>
            <span class="title-text">
              One Time Secret Sharing
            </span>
            <span class="subtitle-text">
              Keep your passwords, links, and other information secure.
            </span>

          </div>
          <div className={classNames(
            classes.flex,
            classes.flexColumn,
            classes.alignItemsCenter
          )}>
            
          </div>
          
          <p class="label-text">
            Enter content below and we'll protect it with <a href="https://tozny.com/blog/end-to-end-encryption-vs-https/" target="_new">end to end encryption</a>.
          </p>
          <FormControl className={custom.FormControl} fullWidth>
          <TextField
            onChange={this.handleToEncryptChange}
            id="toEncrypt"
            label="To Encrypt"
            multiline
            rows="10"
            fullWidth
            style = {{width: '100%'}}
            color="primary"
            placeholder="Enter whatever you need to encrypt..."
            value={this.state.toEncrypt}
            className={classNames(
              classes.bgWhite,
              classes.minWidth,
              classes.inputBox,
              classes.flex,
              classes.fullWidth
            )}
            variant="outlined"
            autoFocus
          />
          <div>
          <div
            className={classNames(
              classes.flex,
              
              classes.alignSelfStart,
              
              classes.mt5
            )}
          >
            
            <p class="label-text">
            How many times can this be decrypted?
            </p>
            </div>
            <div
            className={classNames(
              classes.flex,
              classes.alignSelfStart,
              classes.mt5
            )}
          >
            <Select
              
              style = {{width: '100%', backgroundColor: '#ffffff'}}
              className={custom.select}
              value={this.state.usesAllowed}
              onChange={this.handleUsesChange}
              /* eslint-enable react/jsx-no-duplicate-props*/
              input={
                <OutlinedInput
                  labelWidth={0}
                  name="uses"
                  className={classNames(
                    classes.width100,
                    classes.ml10,
                    classes.textCenter
                  )}
                  style={{ textAlign: 'center', fontSize: '20px', backgroundColor: 'white' }}
                />
              }
            >
              <MenuItem value={1}>1 Time</MenuItem>
              <MenuItem value={2}>2 Times</MenuItem>
              <MenuItem value={3}>3 TImes</MenuItem>
              <MenuItem value={4}>4 Times</MenuItem>
              <MenuItem value={5}>5 Times</MenuItem>
            </Select>
            </div>
          </div>
          </FormControl>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.showErrorMessage}
            autoHideDuration={6000}
            onClose={this.handleClose}
            className={classes.height60}
          >
            <SnackbarContent
              onClose={this.handleClose}
              className={classNames(classes.bgRed, classes.m10, classes.width300, classes.alignSelfCenter)}
              message={this.state.errorMessage}
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={this.handleClose}
                >
                  <CloseIcon />
                </IconButton>
              ]}
            />
          </Snackbar>
          <div>
            <div className={classNames(
              "custom-checkbox-container",
              classes.flex,
              classes.alignItemsCenter,
              classes.alignSelfStart,
              classes.mt5,
              classes.mb5
            )}
            >
              <GreenCheckbox
                onClick={this.handleCheck}
                value="checkedB"
                color="secondary"
                inputProps={{
                  'aria-label': 'secondary checkbox',
                }}
              />
              <label className={classNames("label-text",
                classes.flexColumn,
                
              )}>
                Create your own password
              </label>
            </div>

            {this.state.createPassword ? (<TextField
              onChange={this.handlePasswordChange}
              id="createPassword"
              label="Create Password"
              placeholder="Enter a password..."
              value={this.state.createdPW}
              className={classNames(
                classes.bgWhite,
                classes.width45vw,
                classes.minWidth,
                classes.mt40,
                classes.flex
              )}
              variant="outlined"
              autoFocus
            />) : null}
            
          </div>
          {(this.state.loading) ? (
             <div className={classNames(
              classes.flex,
              classes.alignItemsCenter,
              classes.alignSelfStart,
              classes.justifyCenter,
              classes.mt5,
              classes.mb5,
              classes.flexColumn
            )}>
             <CircularProgress className={classes.progress} />
             
             <p>{this.state.statusMessage}</p>
             </div>
          ) : (
            <Button
                onClick={this.requestEncrypt}
                variant="contained"
                className={classNames(
                  classes.width250,
                  classes.mt40,
                  classes.flex
                )}
                color="secondary"
              >
            Encrypt
          </Button>
          )}
          
          <AboutToznyEnc />
        </div>
        
      </div >
    )
  }
}

export default withStyles(styles)(inject('store')(observer(EncryptPage)))
