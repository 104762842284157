import React, { Component } from 'react'


import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../styles.js'

import { observer, inject } from 'mobx-react'

class ToznyAbout extends Component {
  render() {
    return (
      <div
        className={classNames(
          "tozny-about-box",
        )}
      >
        <p className="about-box-subtitle">Need to protect your customers' data? Your company's data? Tozny makes it easy.</p>
        <p className="about-box-title">Free 30-Day Trial With New Accounts</p>
        <p className="about-general">Create a free account today and get full access to TozId
        <br/> and TozStore.  Tozny integrates with your existing solutions simply <br/>
        and effectively.<br/></p>
      </div>
    )
  }
}

export default withStyles(styles)(inject('store')(observer(ToznyAbout)))
