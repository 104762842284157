import { observable, decorate } from 'mobx'

class Store {
    // FOR DEV ONLY
    encryptedNote = {}

    shareURL = null
    sharePW = null
    urlNoteId = null
    urlEncNonce = null
    urlSignNonce = null
    presentedPassword = null
    requestDecrypt = null
    decryptedData = null
    secretPhrase = null
}

// window.store present for dev
var store = window.store = new Store()

export default store

decorate(store, {
    // FOR DEV ONLY
    encryptedNote: observable,
    
    shareURL: observable,
    sharePW: observable,
    urlNoteId: observable,
    urlEncNonce: observable,
    urlSignNonce: observable,
    presentedPassword: observable,
    requestDecrypt: observable,
    decryptedData: observable,
    secretPhrase: observable
})

