import React, { Component } from 'react'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import AboutToznyEnc from '../common/AboutToznyEnc'

import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../styles.js'

import Snackbar from '@material-ui/core/Snackbar'

import { observer, inject } from 'mobx-react'
import { Typography } from '@material-ui/core'
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import { withRouter } from "react-router"

class DecryptPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      secretPhrase: '',
      errorPresent: false,
    }
  }

  handleClose = e => {
    this.setState({ showErrorMessage: false })
  }

  parseUrlParameters(qs) {
    if (qs.startsWith('?')) {
      qs = qs.substring(1)
    }
    return qs.split('&')
      .map(raw => raw.split('=').map(decodeURIComponent))
      .reduce((acc, current) => {
        const [key, val] = current
        acc[key] = val
        return acc
      }, {})
  }

  componentWillMount = () => {
    try {
      const noteId = this.props.history.location.pathname.split('/').filter(x => x).reverse()[0]
      const hashQuery = this.parseUrlParameters(this.props.history.location.hash.substring(1))
      const {sigSalt, encSalt, password} = hashQuery
      this.props.store.urlNoteId = noteId
      this.props.store.urlSignNonce = sigSalt
      this.props.store.urlEncNonce = encSalt

      if (!this.props.store.previousPassword && password) {
        this.props.store.presentedPassword = password
      } else if (this.props.store.previousPassword) {
        const message = "Invalid password."
        this.setState({ errorMessage: message, showErrorMessage: true })
      }
    } catch (err) {
      this.props.history.push('/404')
    }
  }

  onSecretPhraseChange = (e) => {
    if (this.state.errorPresent) {
      this.setState({ errorPresent: false })
    }
    const id = e.currentTarget.id
    const phrase = e.currentTarget.value
    if (id === 'secretPhrase') {
      this.setState({ secretPhrase: phrase })
    }
  }

  onDecryptData = async () => {
    this.props.store.presentedPassword = this.state.secretPhrase

  }
  keyPress = (e) => {
    if (e.keyCode === 13) {
      this.onDecryptData();
    }
  }

  render() {
    const { classes } = this.props
    return (
      <div
        className={classNames(
          classes.flex,
          classes.flexColumn,
          classes.alignItemsCenter,
          classes.justifyBetween,
        )}
      >
        <div className={classNames(classes.flex, classes.flexColumn, classes.mt40, classes.mb40)}>
          <TextField
            value={this.state.secretPhrase}
            error={this.state.errorPresent}
            onChange={this.onSecretPhraseChange}
            id='secretPhrase'
            placeholder='Enter the pass phrase...'
            className={classNames(
              classes.width350
            )}
            margin="normal"
            /* eslint-disable react/jsx-no-duplicate-props*/
            InputProps={{
              style: { fontSize: '20px', backgroundColor: 'white' }
            }}
            /* eslint-enable react/jsx-no-duplicate-props*/
            variant="outlined"
            onKeyDown={this.keyPress}
          />
          {
            this.state.errorPresent ? (
              <Typography variant='subheading' color='secondary'>{this.state.errorMessage}</Typography>
            ) : null
          }
          <Button
            onClick={this.onDecryptData}
            variant="contained"
            className={classNames(classes.width250, classes.mt10, classes.height60)}
            color="secondary"
            size='large'
          >
            Decrypt Data
          </Button>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            id='passwordFail'
            open={this.state.showErrorMessage}
            autoHideDuration={6000}
            onClose={this.handleClose}
          >
            <SnackbarContent
              onClose={this.handleClose}
              className={classNames(classes.bgRed, classes.m10, classes.width300, classes.alignSelfCenter)}
              message={this.state.errorMessage}
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={this.handleClose}
                >
                  <CloseIcon />
                </IconButton>
              ]}
            />
          </Snackbar>
        </div>

        <div>
          <AboutToznyEnc />
        </div>
      </div>
    )
  }
}


export default withStyles(styles)(inject('store')(observer(withRouter(DecryptPage))))
