const styles = {
  t10: {
    fontSize: '10px'
  },
  t20: {
    fontSize: '20px'
  },
  t40: {
    fontSize: '40px'
  },
  flex: {
    display: 'flex'
  },
  flexColumn: {
    flexDirection: 'column',
  },
  flexWrap: {
    flexWrap: 'wrap'
  },
  justifyBetween: {
    justifyContent: 'space-between'
  },
  justifyCenter: {
    justifyContent: 'center'
  },
  alignItemsCenter: {
    alignItems: 'center'
  },
  alignSelfStart: {
    alignSelf: 'flex-start'
  },
  alignSelfCenter: {
    alignSelf: 'flex-center'
  },
  bgGray: {
    backgroundColor: '#F5F7FA'
  },
  bgWhite: {
    backgroundColor: 'white'
  },
  bgRed: {
    backgroundColor: '#ff1212'
  },
  snackbar: {
    position: 'relative',
    top: '30px'
  },
  height60: {
    height: '60px'
  },
  minHeight: {
    minHeight: '100vh'
  },
  width60: {
    width: '60px',
  },
  width250: {
    width: '250px',
  },
  width300: {
    width: '300px'
  },
  width350: {
    width: '350px',
  },
  width400: {
    width: '400px',
  },
  width45vw: {
    width: '45vw'
  },
  width22vw: {
    width: '22vw'
  },
  minWidth: {
    minWidth: '300px'
  },
  inputBox:{
    width: '95%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  labelBox:{
    width: '95%',
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: '14px',
    marginBottom: '20px',
    textAlign: 'center'
  },
  sublabelBox:{
    width: '95%',
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: '14px',
    marginBottom: '20px',
    textAlign: 'left'
  },
  m5: {
    marginLeft: '5px',
    margin: 'auto'
  },
  mt0: {
    marginTop: '0',
    margin: 'auto'
  },
  mt5: {
    marginTop: '5px',
    margin: 'auto'
  },
  mt10: {
    marginTop: '10px',
    margin: 'auto'
  },
  mt40: {
    marginTop: '40px',
    margin: 'auto',
  },
  mb0: {
    marginBottom: '0px'
  },
  mb5: {
    marginBottom: '5px'
  },
  mb10: {
    marginBottom: '10px'
  },
  mb40: {
    marginBottom: '40px',
  },
  mb64: {
    marginBottom: '64px',
  },
  mr12: {
    marginRight: '12px',
  },
  ml10: {
    marginLeft: '10px',
  },
  ml12: {
    marginLeft: '12px',
  },
  p10: {
    padding: '15px'
  },
  textCenter: {
    textAlign: 'center'
  },
  zoomCheckBox: {
    zoom: '1.5'
  }
}

export default styles
