import React, { Component } from 'react'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import AboutToznyEnc from '../common/AboutToznyEnc'

import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../styles.js'
import { withRouter } from "react-router"
import { observer, inject } from 'mobx-react'

class ViewData extends Component {
  constructor(props) {
    super(props)
    this.state = {
      decryptedValue: ''
    }
  }
  
  goHome = () => {
    this.props.history.push('/')
  }
  render() {
    const { classes } = this.props
    return (
      <div
        className={classNames(
          classes.flex,
          classes.flexColumn,
          classes.alignItemsCenter
        )}
      >
        <div>
          <TextField
            value={this.props.store.decryptedData}
            id="outlined-multiline-static"
            multiline
            rows="16"
            className={classNames(
              classes.bgWhite,
              classes.width45vw,
              classes.minWidth,
              classes.mt40
            )}
            margin="normal"
            variant="outlined"
            InputProps={{
              readOnly: true
            }}
          />
        </div>
        <div>
           <Button
                
                onClick={this.goHome}
                variant="contained"
                className={classNames(classes.ml10, classes.alignSelfCenter, classes.height60, classes.mt5)}
                color="secondary"
                
              >    
              Create A New Secret
            </Button>  
        </div>
        <div>
            <AboutToznyEnc />
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(inject('store')(observer(withRouter(ViewData))))
