import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import store from './store'
import { Provider } from 'mobx-react'

console.log('When loading the libsodium-wrappers library (https://www.npmjs.com/package/libsodium-wrappers), it attempts to load the web assembly code using a data URI. We do not allow data URIs in our Content Security Policy. This does not affect the final behavior of libsodium-wrappers, it just changes how the WASM code is loaded. This error is expected. Learn more about CSP: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Security-Policy. Learn more about the connect-src directive: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Security-Policy/connect-src#Sources')

ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById("root")
  )

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
