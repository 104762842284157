import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import styles from '../styles.js'
import classnames from 'classnames'
import Button from '@material-ui/core/Button'

import { Typography } from '@material-ui/core'

class Page404 extends Component {
  goHome = () => {
    this.props.history.push('/')
  }
  render() {
    const { classes } = this.props

    return (
      <div className={classnames(
        classes.root,
      )}
      id="page404">
        <Typography className={classnames(classes.textCenter, classes.mt40, classes.t40)} variant='h5'>
          Oops! Page not found.
          <p className={classnames(classes.t20, classes.mt10)}>This secret does not exist.</p>
        </Typography>
        <div className={classnames(
          classes.height60,
          classes.mt40,
          classes.flex,
          classes.flexColumn,
          classes.alignItemsCenter
        )}>
          <Button
            onClick={this.goHome}
            variant="contained"
            className={classnames(
              classes.width300,
              classes.mt10,
              classes.flex,
            )}
            color="secondary"
          >
            Create A Secret
            </Button>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(Page404)
